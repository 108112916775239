import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home.jsx";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDbjrXUZracL0JQVoVFdnRmlgt9j6adk14",
  authDomain: "colinlandry-portfolio.firebaseapp.com",
  projectId: "colinlandry-portfolio",
  storageBucket: "colinlandry-portfolio.appspot.com",
  messagingSenderId: "895968407333",
  appId: "1:895968407333:web:e1a2a1e2143214fe29b4cc",
  measurementId: "G-F9FP8MEYXX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export function App() {
  return (
    <>
      <header></header>
      <Home />
      {/* <Routes>
        <Route path="/" component={Home} />
        <Route render={() => <h1>Page not found</h1>} />
      </Routes> */}
      <footer></footer>
    </>
  );
}
