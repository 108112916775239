export default function Home() {
  return (
    <div className="home">
      <h1>Colin Landry</h1>
      <h2>Software Engineer</h2>
      <a href="mailto:colin.a.landry@hotmail.com">colin.a.landry@hotmail.com</a>

      <a href="https://github.com/ColinLandry" target="_blank">
        Github
      </a>
      <a
        href="https://www.linkedin.com/in/colin-landry-781796174/"
        target="_blank"
      >
        Linkedin
      </a>
      {/* <p>
        Colin Landry Full Stack Software Developer Windsor, ON, N9G2T4
        (226)-347-7433 colin.a.landry@hotmail.com
      </p>
      <p>
        Databending, Windsor— Full Stack Web Developer MAY 2020 - Digital Media,
        Windsor—Front/Backend Web Developer OCTOBER 2019 - MARCH 2020 Assisted
        in the development of php/WordPress websites, often integrating with
        external systems and APIs Creating mockups for clients and direct client
        interfacing, group and project management - Undertaking full stack
        projects utilizing React, Express.js, and a custom graphql backend.
      </p>
      <p>
        Natalieromano.ca — Typescript, React, Firebase MARCH 2021 - Contracted
        by an aspiring Windsor artist/animator to create a platform that can be
        used as a portfolio, and as a way to send commission requests. -
        Implemented a customized admin panel with Firebase's login API for the
        client to be able to upload/edit images, view submissions, and edit
        other page information. - Utilized cloud functions to send emails and
        modify images before upload
      </p>
      <li>St. Clair College</li>
      <li> Windsor, ON — Mobile App Development SEPTEMBER 2016 - MAY 2019</li>
      <ul>
        <li>Graduated with a 4.0 GPA throughout all three years</li>
        <li>Faculty Academic Award received for academic excellence</li>
        <li>
          <ul>
            Wireframing tools
            <li>Sketch, Figma, Penpot</li>
            <li>Illustrator</li>
            <li>PhotoShop</li>
          </ul>
        </li>
        <li>
          <ul>
            Project organization and management
            <li>Git</li>
            <li>Microsoft Project</li>
            <li>Jira</li>
          </ul>
        </li>
        <li>Business communication and agile methodologies</li>
        <li>Group projects with weekly documentation and daily meetings </li>
        <li>
          SKILLS/LANGUAGES Languages: Java, Swift, JavaScript, TypeScript,
          HTML/CSS, SCSS, SQL, GRAPHQL, C# (Unity)
        </li>
        <li>
          Frameworks: React, React Native, Node.js, Redux, Express.js Developer
          Tools: Git, VS Code, NPM, yarn, snowpack, Webpack
        </li>
      </ul> */}
    </div>
  );
}
